import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import MapView from 'controlTower/components/MapView';

export default (props) => (
  <Layout {...props}>
    <Router style={{ height: '100%', position: 'relative' }}>
      <MapView path="/ct/map/:type" style={{ height: '100%', position: 'relative' }} />
    </Router>
  </Layout>
);
