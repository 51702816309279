import { IconLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ChevronRight, ExpandLess, ExpandMore, FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import { filterReducer, useStyles } from 'pages/ru/index';
import { VirtualSelect } from 'retailerUniverse/Select';
import { useSettings } from 'utils/customHooks';
import Select from './Select';

import { useQuery } from 'react-query';

import {
  blackM,
  greenM,
  greenMDark,
  redM,
  redMDark,
  yellowM,
  yellowMDark,
} from 'controlTower/chartUtils/colors';
import { format } from 'date-fns';
import branchIcon from 'images/branch.png';
import branchOIcon from 'images/branchO.png';
import vehicleIcon from 'images/vehicle.svg';
import _ from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { StaticMap } from 'react-map-gl';
import { ga, useGDispatch, useGState } from 'state/store';
import { userTypes } from 'utils/constants';
import { useFetch, useInterval } from 'utils/customHooks';
import fetch from 'utils/fetch';
import {
  arrayToObject,
  byId,
  convertDeliveryWindowTo24HourFormat,
  formatTo24HourTime,
  haversine,
  isWithinTrackingTime,
} from 'utils/utils';

const emptyBranchMetrics = {
  dDelivered: 0,
  dPartialDelivered: 0,
  dFailed: 0,
  dTotal: 0,
  sVisited: 0,
  sPassedBy: 0,
  sNotVisited: 0,
  sTotal: 0,
};

export default function MapView({ type }) {
  const MAPBOX_ACCESS_TOKEN = useGState((s) => s[ga.PANEL_CONFIG].MAPBOX_ACCESS_TOKEN);
  const { date, branch } = useGState((s) => ({
    date: s.date,
    branch: s.branch,
    branches: s.branches,
  }));

  const nodes = useGState((s) => s.branches);
  const branches = useMemo(
    () =>
      nodes
        .filter((node) => node.isLeaf)
        .map((node) => ({
          ...node,
          longitude: node?.locations?.[0]?.longitude,
          latitude: node?.locations?.[0]?.latitude,
        })),
    [nodes]
  );
  const [retailersExpand, setRetailersExpand] = useState(true);
  const handleRetailersClick = () => {
    setRetailersExpand(!retailersExpand);
  };
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [codeValue, setCodeValue] = useState();
  const [name, setName] = useState();
  const [channel, setChannel] = useState();
  const [etastate, setetastate] = useState();

  const [tooltip, setTooltip] = useState(null);
  const settings = useSettings();
  const tracking = settings.tracking;
  const [users, loading1, , refetch] = useFetch(`/client/metrics/gps?date=${date}`);
  const [userMetrics, loading2, , refetchUserMetrics] = useFetch(
    `/client/metrics/gps/metrics?date=${date}`
  );
  const gDispatch = useGDispatch();
  const [state, dispatch] = useReducer(filterReducer, {
    universe: null,
    filters: {
      branches: null,
      code: null,
      name: null,
      phone: null,
      channel: null,
      geotagBy: null,
      geotagOn: null,
      retag: null,
      phoneVerified: null,
      hasImage: null,
      mktIndex: null,
      mktName: null,
      dse: null,
    },
    filteredRetailerData: null,
  });

  const [darkMode, setDarkMode] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useInterval(() => refetch(), 120 * 1000);
  useInterval(() => refetchUserMetrics(), 120 * 1000);

  useEffect(() => {
    if (!users) return;
    gDispatch([ga.LAST_UPDATED, `Last updated at ${format(new Date(), 'h:mm a')}`]);
    return () => {
      gDispatch([ga.LAST_UPDATED, '']);
    };
  }, [users, gDispatch]);

  const usersData = useMemo(() => {
    if (!users) return null;
    if (users && !userMetrics) return users;

    const userMetricsObj = arrayToObject(userMetrics);
    return users.map((u) => ({ ...u, ...userMetricsObj[u.id] }));
  }, [users, userMetrics]);
  const data = useMemo(
    () =>
      usersData
        ? usersData.filter((u) => u.lastLocation).map((u) => ({ ...u, ...u.lastLocation }))
        : [],
    [usersData]
  );

  const calculateInitialMapState = (locations) => {
    if (!locations || !locations.length) return null;

    const metrics = locations.reduce(
      (acc, loc) => ({
        centerLong: acc.centerLong + loc.long / locations.length,
        ...(loc.long > acc.maxLong ? { maxLong: loc.long } : { maxLong: acc.maxLong }),
        ...(loc.long < acc.minLong ? { minLong: loc.long } : { minLong: acc.minLong }),

        centerLat: acc.centerLat + loc.lat / locations.length,
        ...(loc.lat > acc.maxLat ? { maxLat: loc.lat } : { maxLat: acc.maxLat }),
        ...(loc.lat < acc.minLat ? { minLat: loc.lat } : { minLat: acc.minLat }),
      }),
      {
        centerLong: 0,
        maxLong: locations[0].long,
        minLong: locations[0].long,
        centerLat: 0,
        maxLat: locations[0].lat,
        minLat: locations[0].lat,
      }
    );

    const {
      centerLat: latitude,
      centerLong: longitude,
      minLat,
      minLong,
      maxLat,
      maxLong,
    } = metrics;
    const distance = haversine(minLat, minLong, maxLat, maxLong);
    return { latitude, longitude, zoom: distance ? Math.log(591657550.5 / distance) - 0.2 : 12 };
  };

  const initialViewState = useMemo(
    () => ({
      pitch: 0,
      bearing: 0,
      ...calculateInitialMapState(
        data && data.map((u) => ({ lat: u.latitude, long: u.longitude }))
      ),
    }),
    [data]
  );
  const branchesData = useMemo(() => {
    if (!branches.length) return [];
    const tmp = branches
      .map((b) => ({ ...b, ...emptyBranchMetrics }))
      .map((b) => {
        if (b.locations.length >= 1) {
          b.latitude = b.locations[0].latitude;
          b.longitude = b.locations[0].longitude;
        }
        return b;
      });
    data.forEach((u) => {
      const branch = byId(tmp, u.branchId);
      if (u.type === userTypes.DB) {
        branch.dDelivered += u.delivered;
        branch.dPartialDelivered += u.partialDelivered;
        branch.dFailed += u.failed;
        branch.dTotal += u.total;
      } else if (u.type === userTypes.SALESMAN) {
        branch.sVisited += u.visited;
        branch.sPassedBy += u.passedBy;
        branch.sNotVisited += u.notVisited;
        branch.sTotal += u.total;
      }
    });
    return tmp;
  }, [branches, data]);

  const filteredBranches = useMemo(() => {
    const gpsBranches = branchesData ? branchesData.filter((b) => b.latitude && b.longitude) : [];
    if (!branch || branch.id === -1) return gpsBranches;
    return gpsBranches.filter((b) => b.id === branch.id || branch.id === b.parentId);
  }, [branchesData, branch]);

  const filteredData = useMemo(() => {
    const typeFilteredData = data.filter((u) => u.type === type);

    const branchFilteredData = typeFilteredData.filter((u) =>
      filteredBranches.some((b) => b.id === u.branchId)
    );

    const getMatchingRetailerIds = (filters, property) => {
      if (!filters || filters.length === 0) return null;

      const matchingRetailerIds = new Set();
      (computedBranchData || []).forEach((branch) => {
        branch?.forEach((retailer) => {
          if (filters.some((filter) => retailer[property] === filter.value)) {
            matchingRetailerIds.add(retailer.id);
          }
        });
      });
      return matchingRetailerIds.size > 0 ? matchingRetailerIds : null;
    };

    const matchingCodeIds = getMatchingRetailerIds(state.filters.code, 'code');
    const codeFilteredData =
      matchingCodeIds !== null
        ? branchFilteredData.filter((u) =>
            u.retailers?.some((retailer) => matchingCodeIds.has(retailer.id))
          )
        : branchFilteredData;

    const matchingNameIds = getMatchingRetailerIds(state.filters.name, 'name');
    const nameFilteredData =
      matchingNameIds !== null
        ? codeFilteredData.filter((u) =>
            u.retailers?.some((retailer) => matchingNameIds.has(retailer.id))
          )
        : codeFilteredData;

    const matchingChannelIds = getMatchingRetailerIds(state.filters.channel, 'channel');
    const channelFilteredData =
      matchingChannelIds !== null
        ? nameFilteredData.filter((u) =>
            u.retailers?.some((retailer) => matchingChannelIds.has(retailer.id))
          )
        : nameFilteredData;
    const enrichedData = channelFilteredData.map((u) => {
      const etaSt = formatTo24HourTime(new Date(u.retailersEta?.[u.delivered]?.eta || null));
      const retailerId = u.retailersEta?.[u.delivered]?.retailerId || null;

      const matchingRetailer = computedBranchData?.find((retailer) => retailer.id === retailerId);

      const deliveryWindow = convertDeliveryWindowTo24HourFormat(
        matchingRetailer?.deliveryWindow || []
      );
      const hasDeliveryWindow = deliveryWindow.length > 0;
      const psuedoStatus = deliveryWindow.map((window) =>
        isWithinTrackingTime(etaSt, window.start, window.end)
      );

      const status =
        etaSt !== '--'
          ? hasDeliveryWindow
            ? psuedoStatus[0] === 'ON-TIME'
              ? 'ON-TIME'
              : psuedoStatus[0] === 'DELAYED'
              ? 'DELAYED'
              : 'BEFORE-TIME'
            : isWithinTrackingTime(etaSt, tracking?.dayStart, tracking?.dayEnd)
          : '--';

      return {
        ...u,
        status, // Add the status field here
      };
    });

    const filteredByStatus = enrichedData.filter((item) => {
      if (!etastate) return true;
      return item.status === etastate; // Filter by status
    });

    return filteredByStatus;
  }, [data, computedBranchData, etastate, filteredBranches, type, state.filters, tracking]);

  const pl = filteredData.map((u) => u.branchId);
  const pointedId = pl[0];
  const cl = useMemo(() => {
    const retailerIds = filteredData.flatMap((item) =>
      item.retailersEta ? Object.values(item.retailersEta).map((entry) => entry.retailerId) : []
    );

    return Array.from(new Set(retailerIds));
  }, [filteredData]);

  const { data: retailersData } = useQuery(
    pl.length > 0 && ['retailers', { pointedId }],
    (_, { pointedId }) => fetch(`/client/metrics/retailer?branchId=${pointedId}`) || [],
    { suspense: false, enabled: pointedId !== 0 }
  );
  const filteredRetailersData = useMemo(() => {
    if (!retailersData || retailersData.length === 0) return [];
    return retailersData.filter((retailer) => cl.includes(retailer.id));
  }, [retailersData, cl]);

  const processedData = useMemo(() => {
    if (!filteredRetailersData) return;
    let data = _.flatten(
      [filteredRetailersData].map((rs, index) =>
        rs.map((r) => ({
          ...r,
          branchId: branches[index].id,
        }))
      )
    );
    dispatch(['SET_DATA', data]);
    dispatch(['SET_FILTERED_DATA', data]);

    return data;
  }, [branches, filteredRetailersData]);

  const [computedBranchData, setComputedBranchData] = useState([]);

  useEffect(() => {
    if (!processedData || !state.universe || !state.filters) {
      setComputedBranchData([]); // Fallback to an empty array
      return;
    }

    const newBranchData =
      state.filters.branches && state.filters.branches.length > 0
        ? state.universe.filter((r) =>
            state.filters.branches.some((filter) => filter.value === r.branchId)
          )
        : state.universe;

    setComputedBranchData(newBranchData);
  }, [processedData, state.universe, state.filters]);
  const retailerCodeOptions = useMemo(() => {
    if (!computedBranchData) return;
    return Object.values(_.uniqBy(computedBranchData, (b) => b.code)).map((b) => ({
      value: b.code,
      label: b.code,
    }));
  }, [computedBranchData]);
  const retailerNames = useMemo(() => {
    if (!computedBranchData) return;
    return Object.values(_.uniqBy(computedBranchData, (b) => b.name)).map((b) => ({
      value: b.name,
      label: b.name,
    }));
  }, [computedBranchData]);
  const retailerChannel = useMemo(() => {
    if (!computedBranchData) return;
    return Object.values(_.uniqBy(computedBranchData, (b) => b.channel)).map((b) => ({
      value: b.name,
      label: b.name,
    }));
  }, [computedBranchData]);
  const handleEtaStatusChange = (event) => {
    setetastate(event.target.value);
  };
  const etaStatusOptions = [
    {
      label: 'ON-TIME',
      value: 'ON-TIME',
    },
    {
      label: 'BEFORE-TIME',
      value: 'BEFORE-TIME',
    },
    {
      label: 'DELAYED',
      value: 'DELAYED',
    },
  ];
  const humansLayer = useMemo(() => {
    if (darkMode && filteredBranches.length === 1 && type === userTypes.DB) {
      return [
        new IconLayer({
          id: 'users_DB',
          data: filteredData,
          getPosition: (u) => [u.longitude, u.latitude, 0],
          getColor: (d) => {
            if (type === userTypes.DB) {
              const percentageDone = d.delivered / d.total;
              return percentageDone > 0.7
                ? greenMDark
                : percentageDone > 0.35
                ? yellowMDark
                : redMDark;
            }
            const percentageDone = d.visited / d.total;
            return percentageDone > 0.7
              ? greenMDark
              : percentageDone > 0.35
              ? yellowMDark
              : redMDark;
          },
          getIcon: () => ({
            url: vehicleIcon,
            width: 64,
            height: 64,
            anchorY: 0,
            anchorX: 16,
            mask: true,
          }),
          sizeUnits: 'common',
          sizeMinPixels: 30,
          sizeMaxPixels: 100,
          sizeScale: 300,
          pickable: true,
          stroked: true,
          getLineColor: blackM,
          onHover: (info) => {
            setTooltip({
              obj: info.object,
              pointerX: info.x,
              pointerY: info.y,
            });
          },
        }),
        new TextLayer({
          id: 'db_labels',
          data: filteredData,
          getPosition: (u) => [u.longitude, u.latitude, 0],
          getText: (d) => `${d.name || d.loginId}  ${d.delivered}/${d.total}`,
          getSize: 16,
          getColor: [255, 255, 255, 255],
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'bottom',
        }),
      ];
    }

    return [
      new ScatterplotLayer({
        id: 'users',
        data: filteredData,
        getPosition: (u) => [u.longitude, u.latitude, 0],
        getFillColor: (d) => {
          if (type === userTypes.DB) {
            const percentageDone = d.delivered / d.total;
            return percentageDone > 0.7 ? greenM : percentageDone > 0.35 ? yellowM : redM;
          }
          const percentageDone = d.visited / d.total;
          return percentageDone > 0.7 ? greenM : percentageDone > 0.35 ? yellowM : redM;
        },
        radiusMinPixels: 4,
        radiusMaxPixels: 10,
        radiusUnits: 'common',
        radiusScale: 30,
        pickable: true,
        stroked: true,
        lineWidthUnits: 'common',
        lineWidthMinPixels: 1,
        lineWidthMaxPixels: 3,
        getLineColor: blackM,
        onHover: (info) => {
          setTooltip({
            obj: info.object,
            pointerX: info.x,
            pointerY: info.y,
          });
        },
      }),
    ];
  }, [filteredBranches, filteredData, type, darkMode]);

  if (!data || !data.length)
    return loading1 || loading2 ? (
      <LinearProgress color="secondary" />
    ) : (
      <Box p={3}>
        <Typography>No location data</Typography>
      </Box>
    );

  const selectItems = [
    {
      heading: 'SHIP TO ',
      name: 'Ship To Code',
      expand: retailersExpand,
      handleClick: handleRetailersClick,
      options: retailerCodeOptions,
      value: codeValue,
      onChange: (v) => {
        dispatch(['UPDATE_CODE', v]);
        setCodeValue(v);
      },
      loadMore: true,
    },
    {
      name: 'Ship to  Name',
      expand: retailersExpand,
      options: retailerNames,
      value: name,
      onChange: (v) => {
        dispatch(['UPDATE_NAME', v]);
        setName(v);
      },
      loadMore: true,
    },
    {
      name: 'Ship to Classification',
      expand: retailersExpand,
      options: retailerChannel,
      value: channel,
      onChange: (v) => {
        dispatch(['UPDATE_CHANNEL', v]);
        setChannel(v);
      },
      loadMore: true,
    },
    {
      heading: 'Delivery ETA status',
      name: 'ETA STATUS',
      expand: retailersExpand,
      options: etaStatusOptions,
      value: etastate,
      handleClick: handleEtaStatusChange,
      loadMore: false,
    },
  ];
  const sideList = () => (
    <div>
      <List>
        <ListItem>
          <p />
        </ListItem>
        {selectItems.map(
          ({ name, heading, options, expand, onChange, handleClick, loadMore, value }, index) => (
            <>
              {heading && (
                <ListItem key={index + selectItems.length} button onClick={handleClick}>
                  <div className={classes.expand}>
                    <ListItemText primary={heading} />
                    {heading && name ? expand ? <ExpandLess /> : <ExpandMore /> : null}
                  </div>
                </ListItem>
              )}
              {name ? (
                <Collapse in={expand}>
                  <ListItem key={index}>
                    <div>
                      <ListItemText classes={{ secondary: classes.ListText }} secondary={name} />
                      {loadMore ? (
                        <VirtualSelect
                          options={options}
                          className={classes.Select}
                          closeOnSelect={true}
                          searchable={true}
                          onChange={onChange}
                          value={value}
                          multi
                        />
                      ) : (
                        <Select
                          closeMenuOnSelect={true}
                          className={classes.Select}
                          options={etaStatusOptions}
                          onChange={(event) => {
                            setetastate(event.value);
                          }}
                          // value={etastate}
                        />
                      )}
                    </div>
                  </ListItem>
                </Collapse>
              ) : (
                <ListItem key={index}>
                  <div>
                    <ListItemText classes={{ secondary: classes.ListText }} secondary={name} />
                    <Select
                      closeMenuOnSelect={false}
                      className={classes.Select}
                      isMulti
                      options={options}
                      onChange={onChange}
                    />
                  </div>
                </ListItem>
              )}
            </>
          )
        )}
      </List>
      <Divider />
    </div>
  );
  const layers = [
    ...humansLayer,
    new IconLayer({
      id: 'branchesColor',
      data: filteredBranches,
      getPosition: (u) => [u.longitude, u.latitude, 0],
      getColor: (b) => {
        if (type === userTypes.DB) {
          const percentageDone = b.dDelivered / b.dTotal;
          return percentageDone > 0.7 ? greenM : percentageDone > 0.35 ? yellowM : redM;
        }
        const percentageDone = b.sVisited / b.sTotal;
        return percentageDone > 0.7 ? greenM : percentageDone > 0.35 ? yellowM : redM;
      },
      getIcon: () => ({
        url: branchIcon,
        width: 64,
        height: 64,
        anchorY: 64,
        mask: true,
      }),
      sizeUnits: 'common',
      sizeMinPixels: 30,
      sizeMaxPixels: 100,
      sizeScale: 300,
      pickable: true,
      onHover: (info) => {
        setTooltip({
          obj: info.object,
          pointerX: info.x,
          pointerY: info.y,
        });
      },
    }),
    new IconLayer({
      id: 'branches',
      data: filteredBranches,
      getPosition: (u) => [u.longitude, u.latitude, 0],
      // getColor: [0, 0, 0, 255],
      getIcon: () => ({
        url: branchOIcon,
        width: 64,
        height: 64,
        anchorY: 64,
        mask: true,
      }),
      sizeUnits: 'common',
      sizeMinPixels: 30,
      sizeMaxPixels: 100,
      sizeScale: 300,
      pickable: true,
      onHover: (info) => {
        setTooltip({
          obj: info.object,
          pointerX: info.x,
          pointerY: info.y,
        });
      },
    }),
  ];

  function renderToolTip() {
    const { obj, pointerX, pointerY } = tooltip || {};
    return (
      obj && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            pointerEvents: 'none',
            left: pointerX,
            top: pointerY,
          }}
        >
          <Box bgcolor="background.paper" p={1} borderRadius={4}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TC
                    text={
                      obj.type && obj.type === userTypes.DB ? obj.name || obj.loginId : obj.name
                    }
                    color="primary"
                  />
                  <TC
                    text={obj.type ? byId(branches, obj.branchId)?.name : 'Branch'}
                    align="right"
                    color="textSecondary"
                  ></TC>
                </TableRow>
              </TableHead>
              {obj.type !== 'branch' ? (
                type === userTypes.SALESMAN ? (
                  <TableBody>
                    <TR label="Visited" value={obj.visited} />
                    <TR label="Passed By" value={obj.passedBy} />
                    <TR label="Not Visited" value={obj.notVisited} />
                    {/* <TR label="In Market time" value={formatTime(obj.inMarketTime / 1000)} />
                    <TR label="In Store time" value={formatTime(obj.inStoreTime / 1000)} /> 
                     <TR
                      label="Start Time"
                      value={obj.marketEntry ? moment(obj.marketEntry).format('LT') : '--'}
                    /> 
                    <TR label="Last Active" value={obj.lastActive} /> */}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TR label="Delivered" value={obj.delivered} />
                    <TR label="Partial" value={obj.partialDelivered} />
                    <TR label="Failed" value={obj.failed} />
                    <TR label="Total" value={obj.total} />
                    {/* <TR
                      label="Start Time"
                      value={obj.startTime ? moment(obj.startTime).format('LT') : '--'}
                    />
                    <TR label="Truck #" value={obj.picklistName} />
                    <TR label="Weight" value={format2(obj.weightUtilization * 100)} />
                    <TR label="Value" value={formatCurrency(obj.sales)} /> */}
                  </TableBody>
                )
              ) : type === userTypes.SALESMAN ? (
                <TableBody>
                  <TR label="Visited" value={obj.sVisited} />
                  <TR label="Passed By" value={obj.sPassedBy} />
                  <TR label="Not Visited" value={obj.sNotVisited} />
                </TableBody>
              ) : (
                <TableBody>
                  <TR label="Delivered" value={obj.dDelivered} />
                  <TR label="Partial" value={obj.dPartialDelivered} />
                  <TR label="Failed" value={obj.dFailed} />
                  <TR label="Total" value={obj.dTotal} />
                </TableBody>
              )}
            </Table>
          </Box>
        </div>
      )
    );
  }

  return (
    <>
      <Box
        bgcolor="background.paper"
        color="textPrimary"
        borderRadius={4}
        boxShadow={1}
        pl={2}
        position="absolute"
        bottom={20}
        left={16}
        zIndex="modal"
      >
        <FormControl component="fieldset">
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                checked={darkMode}
                onChange={() => setDarkMode((d) => !d)}
              />
            }
            label="DarkMode"
            labelPlacement="end"
          />
        </FormControl>
      </Box>
      {(!state.filteredRetailerData || !state.filteredRetailerData.length) && (
        <LinearProgress color="secondary" />
      )}
      <DeckGL
        initialViewState={initialViewState}
        // viewState={viewState}
        controller={true}
        layers={layers}
      >
        <StaticMap
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          mapStyle={
            darkMode
              ? 'mapbox://styles/saby/cjvll2l6g00aa1cnq239v1f1o'
              : 'mapbox://styles/mapbox/streets-v11'
          }
        />
        {renderToolTip()}
      </DeckGL>
      <Box zIndex="modal">
        <IconButton
          sx={{
            color: 'secondary.main',
            '&:hover': {
              color: 'secondary.main',
            },
          }}
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(classes.fab, {
            [classes.hide]: open,
          })}
        >
          <FilterList />
        </IconButton>
      </Box>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      ></main>
      <Drawer
        className={classes.drawer}
        anchor="right"
        variant="persistent"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box height={1}>
          {sideList()}
          <Box bottom={0} maxHeight={0.06} position="fixed" top="auto" width={1}>
            <div className={classes.drawerClose}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRight fontSize="small" />
              </IconButton>
            </div>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

const smallText = (text, color = 'textPrimary') => (
  <Typography variant="caption" color={color}>
    {text}
  </Typography>
);

const TC = ({ text, align = 'left', color }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.tableCell} align={align}>
      {smallText(text, color)}
    </TableCell>
  );
};

const TR = ({ label, value }) => {
  return (
    <TableRow>
      <TC text={label}></TC>
      <TC text={value} align="center"></TC>
    </TableRow>
  );
};
